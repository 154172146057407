// App.tsx
import { useEffect, useState } from 'react';
import Routes from 'routes/Routes';
import 'assets/scss/Saas.scss';
import { Spinner } from './components';
import Inactivity from 'pages/other/Inactivity';
import useRedux from 'hooks/useRedux';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { oAuthLogin } from 'redux/actions';
import { useLoadScript } from '@react-google-maps/api';
import config from '../src/config';
import { mapLibraries } from './pages/routing/fleet-view/complements/FleetUtils';

const timeout = 43_200_000;
const promptBeforeIdle = 60_000;

const App = () => {
    const { dispatch } = useRedux();
    const auth = useAuth();

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: config.GOOGLE_MAPS_API_KEY!,
        version: 'beta',
        // @ts-ignore
        libraries: mapLibraries,
    });

    // automatically sign-in
    useEffect(() => {
        if (!isLoaded) {
            console.log('Google Maps API is loading...');
        }

        // Only handle authentication if we have auth params or user is already authenticated
        if (hasAuthParams() || auth.isAuthenticated) {
            if (auth.isAuthenticated && auth.user) {
                dispatch(oAuthLogin({ ...auth.user, ...auth.user?.profile }, auth.user?.access_token || ''));
            }
        }
    }, [auth, isLoaded]);

    if (auth.isLoading) {
        return <Spinner className="middle_center_spinner" />;
    }

    return (
        <>
            <Routes />
            <Inactivity timeout={timeout} promptBeforeIdle={promptBeforeIdle} />
        </>
    );
};

export default App;
