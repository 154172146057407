import APICoreNewClient from 'helpers/api/apiCoreNewClient';
import React, { createContext, useContext } from 'react';
import { useAuth } from 'react-oidc-context';

const APICoreContext = createContext<APICoreNewClient | null>(null);

export const APICoreProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const auth = useAuth();

    const apiCore = new APICoreNewClient(auth);

    return (
        <APICoreContext.Provider value={apiCore}>
            {children}
        </APICoreContext.Provider>
    );
};

export const useAPICore = (): APICoreNewClient => {
    const context = useContext(APICoreContext);
    if (!context) {
        throw new Error('useAPICore must be used within an APICoreProvider');
    }
    return context;
};
