import { BTAction } from "redux/constants";
import { store } from "redux/store";
import { Domain, DomainActionTypes, DomainMap, DomainPayload, DomainReducer } from "./constants";
import  DomainsService from "./DomainsService";
import APICoreNewClient from "helpers/api/apiCoreNewClient";

export const updateDomain = (type: DomainActionTypes, name: DomainReducer, value: Domain[] | DomainMap |{id : number , code : string , description : string}[]) => {
  const action: BTAction<DomainPayload> = {
    type,
    payload: {
      domain: {
        name,
        value,
      }
    }
  }
  return action;
}
export const updateHubDeliveryPartners = (client: APICoreNewClient) => {
  DomainsService.getHubDeliveryPartners(client).then(
      response => store.dispatch(updateDomain(DomainActionTypes.HUB_DELIVERY_PARTNERS, "hubDeliveryPartners", response))
  );
}
export const updateRoutingParties = (client: APICoreNewClient) => {
  DomainsService.getRoutingParties(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_ROUTING_PARTIES_DOMAIN, "routingParties", response))
  );
}
export const updateCompanies = (client: APICoreNewClient) => {
  DomainsService.getCompanies(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_COMPANIES_DOMAIN, "companies", response))
  );
}

export const updateCountries = (client: APICoreNewClient) => {
  DomainsService.getCountries(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_COUNTRIES_DOMAIN, "countries", response))
  );
}

export const updateMetroCodes = (client: APICoreNewClient) => {
  DomainsService.getMetroCodes(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_METRO_CODES_DOMAIN, "metroCodes", response))
  );
}

export const updateMetroCodesIds = (client: APICoreNewClient) => {
  DomainsService.getMetroCodesIds(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_METRO_CODEIDS_DOMAIN, "metroCodesIds", response))
  );
}

export const updateMetroZones = (client: APICoreNewClient) => {
  DomainsService.getMetroZones(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_METRO_ZONES_DOMAIN, "metroZones", response))
  );
}

export const updateOnFleetTeams = (client: APICoreNewClient) => {
  DomainsService.getOnFleetTeams(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_ONFLEET_TEAMS_DOMAIN, "onFleetTeams", response))
  );
}

export const updateOpsHubs = (client: APICoreNewClient) => {
  DomainsService.getOpsHubs(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_OPS_HUB_DOMAIN, "opsHubs", response))
  )
}

export const updateHubMetroConfigurations = (client: APICoreNewClient) => {
  DomainsService.getHubMetroConfigurations(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_HUB_METRO_CONFIGURATIONS, "hubMetroConfigurations", response))
  )

}

export const updateServiceLevels = (client: APICoreNewClient) => {
  DomainsService.getServiceLevels(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_SERVICE_LEVELS_DOMAIN, "serviceLevels", response))
  )
}

export const updateServiceLevelsToken = (client: APICoreNewClient) => {
  DomainsService.getServiceLevelsToken(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_SERVICE_LEVELS_DOMAIN_TOKEN, "serviceLevelsToken", response))
  )
}

export const updateStates = (client: APICoreNewClient) => {
  DomainsService.getStates(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_STATES_DOMAIN, "states", response))
  );
}

export const updateStateCodes = (client: APICoreNewClient) => {
  DomainsService.getStateCodes(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_STATE_CODES_DOMAIN, "stateCodes", response))
  )
}
export const updateStateCodesAbbr = (client: APICoreNewClient) => {
  DomainsService.getStateCodesAbbr(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_STATES_ABBR_DOMAIN, "stateAbbr", response))
  )
}

export const updateMerchants = (client: APICoreNewClient) => {
  DomainsService.getMerchants(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_MERCHANTS_DOMAIN, "merchants", response))
  );
}

export const updateCurrencies = (client: APICoreNewClient) => {
  DomainsService.getCurrencies(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_CURRENCIES_DOMAIN, "currencies", response))
  )
}

export const updateZipCodeExceptionTypes = (client: APICoreNewClient) => {
  DomainsService.getZipCodeExceptionTypes(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_CURRENCIES_DOMAIN, "zipCodeExectionTypes", response))
  )
}

export const updateUserRoles = (client: APICoreNewClient) => {
  DomainsService.getUserRoles(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_USER_ROLES, "userRoles", response))
  )
}

export const updateZipCodes = (client: APICoreNewClient) => {
  DomainsService.getZipCodes(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_USER_ROLES, "zipCodes", response))
  )
}

export const updateClaimStatus = (client: APICoreNewClient) => {
  DomainsService.getClaimStatus(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_CLAIM_STATUS, "claimStatus", response))
  )
}

export const updateFailureReason = (client: APICoreNewClient) => {
  DomainsService.getFailureResons(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_FAILURE_REASON, "failureReason", response))
  )
}

export const updateEventTypes = (client: APICoreNewClient) => {
  DomainsService.getEventTypes(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_EVENT_TYPES, "eventTypes", response))
  );
}

export const updateClaimReasons = (client: APICoreNewClient) => {
  DomainsService.getClaimReasons(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_CLAIM_REASONS, "claimReasons", response)),
  )
}
export const updateThirdPartyRoutingSearches = (client: APICoreNewClient) => {
  DomainsService.getThirdPartyRoutingSearches(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_THIRD_PARTY_SHIPMENTS_SEARCHES, "thirdPartyShipmentsSearches", response))
  )
}

export const updateRoutingTaskSearches = (client: APICoreNewClient) => {
  DomainsService.getRoutingTaskSearches(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_ROUTING_TASK_SEARCH, "routingTaskSearches", response))
  )
}

export const updateELSSearches = (client: APICoreNewClient) => {
  DomainsService.getELSSearches(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_ELS_SEARCH, "elsSearches", response))
  )
}

export const updateMassUnits = (client: APICoreNewClient) => {
  DomainsService.getMassUnits(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_MASS_UNITS, "massUnits", response))
  )
}

export const updateDistanceUnits = (client: APICoreNewClient) => {
  DomainsService.getDistanceUnits(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_DISTANCE_UNITS, "distanceUnits", response))
  )
}

export const updateEventTypesWithDesc = (client: APICoreNewClient) => {
  DomainsService.getEventTypesWithDesc(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_DISTANCE_UNITS, "eventTypesWithDesc", response))
  )
}

export const updatePricingRules = (client: APICoreNewClient) => {
  DomainsService.getPricingRules(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_PRICING_RULE, "pricingRules", response.result))
  );
}

export const updatePodsFailureReasons = (client: APICoreNewClient) => {
  DomainsService.getPodsFailureReasons(client).then(
      response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_PODS_FAILURE_REASONS, "podsFailureReasons", response))
  )
}

export const updateEventImageTypes = (client: APICoreNewClient) => {
  DomainsService.getEventImageTypes(client).then(
      response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_EVENT_IMAGE_TYPES, "eventImageTypes", response))
  )
}

export const updateParcelClaimsSearches = (client: APICoreNewClient) => {
  DomainsService.getParcelClaimsSearches(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_PARCEL_CLAIM_SEARCH, "parcelClaimSearches", response))
  )
}

export const updatePartnerMonitoringSearches = (client: APICoreNewClient) => {
  DomainsService.getPartnerMonitoringSearches(client).then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_PARTNER_MONITORING_SEARCHES, "partnerMonitoringSearches", response))
  )
}

export const initDomains = (client: APICoreNewClient) => {
  updateHubDeliveryPartners(client);
  updateCompanies(client);
  updateRoutingParties(client);
  updateCountries(client);
  updateMetroCodes(client);
  updateMetroCodesIds(client);
  updateMetroZones(client);
  updateOnFleetTeams(client);
  updateServiceLevels(client);
  updateStates(client);
  updateMerchants(client);
  updateCurrencies(client);
  updateZipCodeExceptionTypes(client);
  updateUserRoles(client);
  updateZipCodes(client);
  updateClaimStatus(client);
  updateFailureReason(client);
  updateEventTypes(client);
  updateClaimReasons(client);
  updateThirdPartyRoutingSearches(client);
  updateRoutingTaskSearches(client);
  updateELSSearches(client);
  updateMassUnits(client);
  updateDistanceUnits(client);
  updateStateCodes(client);
  updateStateCodesAbbr(client);
  updateEventTypesWithDesc(client);
  updateServiceLevelsToken(client);
  updateMetroCodesIds(client);
  updateOpsHubs(client);
  updatePodsFailureReasons(client);
  updateEventImageTypes(client);
  updateHubMetroConfigurations(client);
  updateParcelClaimsSearches(client);
  updatePartnerMonitoringSearches(client);
}