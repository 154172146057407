import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { AuthProvider } from 'react-oidc-context';
import config from 'config';
import { WebStorageStateStore } from 'oidc-client-ts';

export interface MultipleAuthProviderInterface {
    setProvider: (provider: AuthProviderType) => void;
    isProviderChanged: boolean;
}

export interface MultipleAuthProvidersOptions {
    children?: React.ReactNode;
}

export enum AuthProviderType {
    Zitadel,
    Okta,
}

export const useMultipleProviders = () => useContext(MultipleAuthContext);

export const MultipleAuthContext = createContext<MultipleAuthProviderInterface>({} as MultipleAuthProviderInterface);

export const MultipleAuthProviders = (opts: MultipleAuthProvidersOptions) => {
    let storeProvider = localStorage.getItem('provider');
    let parsedValue =
        storeProvider == null || storeProvider == ''
            ? null
            : AuthProviderType[storeProvider as keyof typeof AuthProviderType];

    const [currentProvider, setCurrentProvider] = useState(parsedValue);
    const [isProviderChanged, setIsProviderChanged] = useState(false);

    const [providers] = useState([
        {
            provider: AuthProviderType.Zitadel,
            config: {
                authority: `${config.ZITADEL_AUTHORITY || ''}`,
                client_id: `${config.ZITADEL_CLIENT_ID || ''}`,
                scope: 'openid profile email offline_access',
                redirect_uri: `${window.location.origin}/home`,
                post_logout_redirect_uri: `${window.location.origin}/account/login`,
            },
        },
    ]);

    const setProvider = useCallback(
        (provider: AuthProviderType | null) => {
            localStorage.setItem('provider', provider?.toString() ?? '');
            setIsProviderChanged(true);
            setCurrentProvider(provider);
        },
        [setCurrentProvider]
    );

    const contextValue = useMemo<MultipleAuthProviderInterface>(() => {
        return {
            setProvider,
            isProviderChanged,
        };
    }, [setProvider, currentProvider]);

    return (
        <MultipleAuthContext.Provider value={contextValue}>
            <AuthProvider
                onSigninCallback={() => window.history.replaceState({}, document.title, window.location.pathname)}
                stateStore={new WebStorageStateStore({ store: window.localStorage })}
                userStore={new WebStorageStateStore({ store: window.localStorage })}
                key={currentProvider}
                {...providers.find(
                    (x) =>
                        currentProvider == null ||
                        x.provider === currentProvider ||
                        AuthProviderType[x.provider].toString() == currentProvider.toString()
                )?.config}>
                {opts.children}
            </AuthProvider>
        </MultipleAuthContext.Provider>
    );
};

export default MultipleAuthProviders;
