// ProtectedRoute.tsx
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { initDomains } from 'redux/actions';
import { useRedux } from 'hooks';
import { useAuth } from 'react-oidc-context';
import { useApiClient } from 'helpers/api/apiCoreNewClient';

interface ProtectedRouteProps {
    component: React.ComponentType<any>;
    roles: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, roles }) => {
    const { appSelector } = useRedux();
    const [hasRequiredRole, setHasRequiredRole] = useState<boolean | null>(null);
    const auth = useAuth();
    const client = useApiClient();
    const location = useLocation();

    const { domains } = appSelector((state) => ({
        domains: state.Domains.list,
    }));

    useEffect(() => {
        if (!auth.isAuthenticated) {
            setHasRequiredRole(null);
            return;
        }

        const rolesClaimName = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
        const userRoles = (auth?.user?.profile[rolesClaimName] as string[]) || [];
        const hasRole = roles.some((role) => userRoles.includes(role));
        setHasRequiredRole(hasRole);
    }, [auth, roles]);

    // Handle authentication
    if (!auth.isAuthenticated && !auth.isLoading) {
        // Redirect to login page with the return url
        return <Navigate to="/account/login" state={{ from: location }} replace />;
    }

    if (auth.isLoading) {
        return <div>Loading...</div>;
    }

    // Initialize domains if needed
    if (!domains && hasRequiredRole) {
        initDomains(client);
    }

    // Handle role-based access
    if (hasRequiredRole === null) {
        return <div>Loading...</div>;
    }

    return hasRequiredRole ? <Component /> : <Navigate to="/access/denied" />;
};

export default ProtectedRoute;
