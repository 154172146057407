import { store } from 'redux/store';
import { parseJwt } from 'utils/ParseToken';
import {
    Domain,
    DomainMetro,
    DomainOnFleet,
    DomainMerchant,
    DomainFailureReasons,
    DomainEventTypes,
    DomainOnFleetDriver,
    PricingRule,
    DomainPodsFailureReason,
    DomainEventImageTypes,
    DomainHubDeliveryPartners,
} from './constants';
import APICoreNewClient from 'helpers/api/apiCoreNewClient';
const GetDomain = <T>(client: APICoreNewClient, route: string, params: any = {}) =>
    client.get<T>(route, { ...params, hideError: true });

const DomainsService = {
    getHubDeliveryPartners: (client: APICoreNewClient) => {
        return GetDomain<DomainHubDeliveryPartners[]>(client, `api/HubDeliveryPartners`);
    },
    getRoutingParties: (client: APICoreNewClient) => {
        return GetDomain<Domain[]>(client, `api/Helpers/third-party-carrier-dropdown`);
    },
    getCities: (client: APICoreNewClient, stateId: number) => {
        return GetDomain<Domain[]>(client, `api/Helpers/cities-dropdown/${stateId}`);
    },
    getCompanies: (client: APICoreNewClient) => {
        return GetDomain<Domain[]>(client, 'api/Helpers/companies-dropdown');
    },
    getCountries: (client: APICoreNewClient) => {
        return GetDomain<Domain[]>(client, 'api/Helpers/countries-dropdown');
    },
    getMetroCodes: (client: APICoreNewClient) => {
        return GetDomain<DomainMetro[]>(client, 'api/Helpers/metro-codes-dropdown');
    },
    getMetroCodesIds: (client: APICoreNewClient) => {
        return GetDomain<DomainMetro[]>(client, 'api/Helpers/metro-codes-with-ids-dropdown');
    },
    getMetroZones: (client: APICoreNewClient) => {
        return GetDomain<DomainMetro[]>(client, 'api/Helpers/metro-zones-dropdown');
    },
    getOnFleetTeams: (client: APICoreNewClient) => {
        return GetDomain<DomainOnFleet[]>(client, 'api/Helpers/onfleet-teams-dropdown');
    },
    getOnFleetTeamDrivers: (client: APICoreNewClient, teamId: string) => {
        return GetDomain<DomainOnFleetDriver[]>(client, `api/Helpers/onfleet-team-drivers-dropdown/${teamId}`);
    },
    getOpsHubs: (client: APICoreNewClient) => {
        return GetDomain<Domain[]>(client, 'api/Helpers/ops-hubs-dropdown');
    },
    getHubMetroConfigurations: (client: APICoreNewClient) => {
        return GetDomain<Domain[]>(client, 'api/Helpers/hub-metro-configurations-dropdown');
    },
    getServiceLevels: (client: APICoreNewClient) => {
        return GetDomain<Domain[]>(client, 'api/Helpers/service-levels-dropdown');
    },
    getServiceLevelsToken: (client: APICoreNewClient) => {
        return GetDomain<Domain[]>(client, 'api/Helpers/service-levels-tokens-dropdown');
    },
    getServiceLevelsByCompanyId: (client: APICoreNewClient, companyId: number) => {
        return GetDomain<Domain[]>(client, `api/Helpers/service-levels-dropdown/${companyId}`);
    },
    getStates: (client: APICoreNewClient) => {
        return GetDomain<Domain[]>(client, 'api/Helpers/states-dropdown');
    },
    getStateCodes: (client: APICoreNewClient) => {
        return GetDomain<Domain[]>(client, 'api/Helpers/states-codes-dropdown');
    },
    getStateCodesAbbr: (client: APICoreNewClient) => {
        return GetDomain<Domain[]>(client, 'api/Helpers/states-abbr-dropdown');
    },
    getMerchants: (client: APICoreNewClient) => {
        return GetDomain<DomainMerchant[]>(client, 'api/Helpers/merchants-dropdown');
    },
    getCurrencies: (client: APICoreNewClient) => {
        return GetDomain<Domain[]>(client, 'api/Helpers/currencies-dropdown');
    },
    getZipCodeExceptionTypes: (client: APICoreNewClient) => {
        return GetDomain<Domain[]>(client, 'api/Helpers/zip-code-exception-types-dropdown');
    },
    getUserRoles: (client: APICoreNewClient) => {
        return GetDomain<Domain[]>(client, 'api/Helpers/roles-dropdown');
    },
    getZipCodes: (client: APICoreNewClient) => {
        return GetDomain<Domain[]>(client, 'api/Helpers/zip-codes-dropdown');
    },
    getClaimStatus: (client: APICoreNewClient) => {
        return GetDomain<Domain[]>(client, 'api/Helpers/claim-status-dropdown');
    },
    getFailureResons: (client: APICoreNewClient) => {
        return GetDomain<DomainFailureReasons[]>(client, 'api/Helpers/failure-reason-dropdown');
    },
    getEventTypes: (client: APICoreNewClient) => {
        return GetDomain<DomainEventTypes[]>(client, 'api/Helpers/event-types-dropdown');
    },
    getClaimReasons: (client: APICoreNewClient) => {
        return GetDomain<Domain[]>(client, 'api/Helpers/claim-reasons-dropdown');
    },
    getPodsFailureReasons: (client: APICoreNewClient) => {
        return GetDomain<DomainPodsFailureReason[]>(client, 'api/Events/GetPodReviewFailureCodes');
    },
    getEventImageTypes: (client: APICoreNewClient) => {
        return GetDomain<DomainEventImageTypes[]>(client, 'api/Events/images/types');
    },
    getThirdPartyRoutingSearches: (client: APICoreNewClient) => {
        const userId = parseJwt(store.getState().Auth?.authorization?.token || '').userId;
        return GetDomain<Domain[]>(client, 'api/ThirdPartyCarrierShipments/SavedSearches', {
            params: {
                loggedInUserId: userId,
            },
        });
    },
    getRoutingTaskSearches: (client: APICoreNewClient) => {
        const userId = parseJwt(store.getState().Auth?.authorization?.token || '').userId;
        const userEmail = store.getState().Auth?.user?.email;
        return GetDomain<Domain[]>(client, 'api/Helpers/routing-tasks-searches-dropdown', {
            params: {
                loggedInUserEmail: userEmail,
            },
        });
    },

    getELSSearches: (client: APICoreNewClient) => {
        const userId = parseJwt(store.getState().Auth?.authorization?.token || '').userId;
        const userEmail = store.getState().Auth?.user?.email;
        return GetDomain<Domain[]>(client, 'api/Helpers/els-searches-dropdown', {
            params: {
                loggedInUserEmail: userEmail,
            },
        });
    },

    getMassUnits: (client: APICoreNewClient) => {
        return GetDomain<Domain[]>(client, 'api/Helpers/mass-units-dropdown');
    },
    getDistanceUnits: (client: APICoreNewClient) => {
        return GetDomain<Domain[]>(client, 'api/Helpers/distance-units-dropdown');
    },
    getEventTypesWithDesc: (client: APICoreNewClient) => {
        return GetDomain<{ id: number; code: string; description: string }[]>(
            client,
            'api/Helpers/event-types-with-decriptions-dropdown'
        );
    },
    getPricingRules: (client: APICoreNewClient) => {
        return GetDomain<{ result: PricingRule[] }>(client, 'api/DynamicPricing/PricingRuleDetails');
    },
    getParcelClaimsSearches: (client: APICoreNewClient) => {
        const userEmail = store.getState().Auth?.user?.email;
        return GetDomain<Domain[]>(client, 'api/Helpers/parcel-claims-searches-dropdown', {
            params: {
                loggedInUserEmail: userEmail,
            },
        });
    },
    getPartnerMonitoringSearches: (client: APICoreNewClient) => {
        const userId = parseJwt(store.getState().Auth?.authorization?.token || '').userId;

        return GetDomain<Domain[]>(client, 'api/PartnerMonitoring/SavedSearches', {
            params: {
                loggedInUserId: userId,
            },
        });
    },
};

export default DomainsService;
