import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AccountLayout from './AccountLayout'; // Adjust the import based on your structure
import { Col, Row } from 'react-bootstrap';
import { hasAuthParams, useAuth } from 'react-oidc-context';

const BottomLink = () => {
    const { t } = useTranslation();

    return (
        <Row className="mt-3">
            <Col className="text-center">
                <p className="text-muted">
                    {t('To ')}{' '}
                    <Link to={'/account/login'} className="text-muted ms-1">
                        <b>{t('Log In')}</b>
                    </Link>
                </p>
            </Col>
        </Row>
    );
};

export type LogoutParameters = {
    url?: String;
};

const RedirectLogout = (data?: LogoutParameters) => {
    const { t } = useTranslation();


    const auth = useAuth()
    
    useEffect(() => {

        if (!hasAuthParams() &&
            !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading
        ) {
            // auth.signinRedirect(); 
        } else { 
            auth.signoutRedirect()
        }
    }, [auth]);

    return (
        <AccountLayout bottomLinks={<BottomLink />}>
            <div className="text-center w-75 m-auto">
                <h4 className="text-dark-50 text-center mt-0 fw-bold">{t('Redirecting for sign out')}</h4>
            </div>
        </AccountLayout>
    );
};

export default RedirectLogout;