import { HyperDatepickerProps } from "components/Datepicker";
import { format, Locale } from "date-fns";

interface IFormatOptions {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
}

export const formatDate = (date: Date | number | string | null, formatter?: string, options?: IFormatOptions) => {
    if (!date) return null;

    let dt = date;

    if (typeof dt === "string") {
        if (dt.includes("+"))
            dt = dt.substring(0, dt.indexOf('+'));
        if (!dt.includes("GMT") && !dt.includes("Z")) {
            dt = dt + "Z";
        }

        dt = new Date(dt);
    }

    if (typeof dt === "number") {
        dt = new Date(dt);
    }
    
    return format(dt, formatter || "MM/dd/yyyy hh:mm aa", options);
};

interface IDateInputFormats {
    american:  Omit<HyperDatepickerProps, "onChange" | "value">;
}

export const formatAddressCorrectionSurchargeDate = (dateStr: string | number | Date) => {
    if (!dateStr) return '';
    const date = new Date(dateStr);

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');

    return `${month}/${day}/${year}`;
  };

export const DateInputFormats: IDateInputFormats = {
    american: {
        dateFormat: "MM/dd/yyyy HH:mm:ss",
        showTimeSelect: true,
        timeFormat: "HH:mm",
        hideAddon: true
    },
}