import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { createZitadelAuth, ZitadelConfig } from "@zitadel/react";

interface LoginCallbackProps {
    loadingElement?: JSX.Element;
    errorComponent: (error: Error) => JSX.Element;
}

const LoginCallback = ({ loadingElement, errorComponent }: LoginCallbackProps) => {
    
    const navigate = useNavigate();
    
    navigate('/home')
    

    

     

    // useEffect(() => {

    //     let userInfo = await zitadel.userManager.getUser()

    //     if (!userInfo) {
    //         // Redirect the user after successful authentication
    //         navigate('/home');
    //     }
    // }, [isLoading, isAuthenticated, navigate]);

    
    navigate('/home');

    // if (isLoading) {
    //     // Show loading spinner during Auth0 processing
    //     return loadingElement || <Spinner />;
    // }

    // if (error) {
    //     // Handle error (e.g., display error page)
    //     return errorComponent(error);
    // }

    return null; // Nothing to show in case of a successful login, as the user will be redirected
};

export default LoginCallback;
